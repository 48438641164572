import * as React from 'react';
import Header from '../layouts/header-dark';
import Footer from '../layouts/footer';
import { Form, Button } from 'react-bootstrap';
import '../styles/_bootstrap.scss'
import './index.scss';

interface contactState {
    newCust: Boolean,
    formExtras: string
}

class ContactUs extends React.PureComponent<{}, contactState> {
    constructor(props: any) {
        super(props)
        this.state = {
            newCust: true,
            formExtras: ''
        }

        this.showExtraFields = this.showExtraFields.bind(this)
    }

    private showExtraFields = (e: any) => {

        e.persist()
        const subfieldSet: String = e.target[e.target.selectedIndex].getAttribute('data-show')
        
        switch (subfieldSet) {
            case ".price-query":
                this.setState({ formExtras: '.price-query' })
                break;
            case ".where-is":
                this.setState({ formExtras: '.where-is' })
                break;
            case ".missing-parts":
                this.setState({ formExtras: '.missing-parts' })
                console.log(this.state.formExtras + " missing parts")

                break;
            case ".return-req":
                this.setState({ formExtras: '.return-req' })
                console.log(this.state.formExtras)

                break;
            case ".accounts-query":
                this.setState({ formExtras: '.accounts-query' })
                break;
            case ".other":
                this.setState({ formExtras: '.other' })
                break;
            default:
                console.log("defaulted")
                console.log(this.state.formExtras)
                this.setState({ formExtras: '' })
        }
    }
    private hidden = (e: any) => {
        if (e.target.parentNode.parentNode.classList.contains('active')) return
        this.setState({ newCust: !this.state.newCust })

    }
    public render() {
        return (
            <>
                <div className="page">
                    <Header />
                    <div className="container-fluid hero">
                    </div>
                    <div className="container-fluid padding-none body">
                        <section className="padding-bottom-M section contact">
                            <div className="container">
                                <h1 className="text-center padding-bottom-M">Contact our team</h1>
                                <ul className="col-md-8 col-md-offset-2 col-sm-12 list-unstyled customer-select" role="tablist">

                                    <li role="presentation" className={`col-sm-12 col-md-6 text-center border-all padding-none ${this.state.newCust ? 'active' : ''}`}>
                                        <a aria-controls="new" role="tab" data-toggle="tab" onClick={this.hidden} ><h2 className="padding-top-L padding-bottom-L margin-none"  >New Customer</h2></a>
                                    </li>
                                    <li role="presentation" className={`col-sm-12 col-md-6 text-center border-all padding-none ${!this.state.newCust ? 'active' : ''} second-item`}>
                                        <a aria-controls="existing" role="tab" data-toggle="tab" onClick={this.hidden}><h2 className="padding-top-L padding-bottom-L margin-none"  >Existing Customer</h2></a>
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <section className="padding-top-L contact">
                            <div className="container">
                                <div className="col-md-8 col-md-offset-2 col-sm-12 tab-content">
                                    {/* <!-- NEW CUSTOMER CONTACT FROM --> */}
                                    <div className="col-md-8 col-md-offset-2 col-sm-12 tab-content" style={{ display: this.state.newCust ? 'block' : 'none' as 'collapse' }}>
                                        <Form action="https://formspree.io/sales@direktek.co.uk" id="new" method="post" name="new">
                                            <p className="text-center text-XL padding-bottom-L">We'd love to talk about working together</p>

                                            <Form.Group controlId="newCustomer">
                                                <div className="form-group col-sm-6 col-xs-12 form-item left">
                                                    <Form.Control type="text" name="name" placeholder="Your name..." />
                                                </div>
                                                <div className="form-group col-sm-6 col-xs-12 form-item">
                                                    <Form.Control type="email" name="_replyto" placeholder="Your e-mail" />
                                                </div>
                                                <div className="form-group col-sm-6 col-xs-12 form-item left">
                                                    <Form.Control type="text" name="jobTitle" placeholder="Job Title" />
                                                </div>
                                                <div className="form-group col-sm-6 col-xs-12 form-item">
                                                    <Form.Control type="text" name="website" placeholder="Website" />
                                                </div>
                                                <div className="form-group col-xs-12 form-item">
                                                    <Form.Control as="textarea" placeholder="Tell us more about your business - what are your needs?" rows={3} />
                                                </div>
                                            </Form.Group>
                                            <Form.Group controlId="hidden">
                                                <Form.Control type="hidden" name="formTitle" value="New customer contact form" />
                                                <Form.Control type="hidden" name="_next" value="/contact" />
                                                <Form.Control type="hidden" name="_format" value="plain" />
                                            </Form.Group>
                                            <div className="form-group col-xs-12 col-sm-6 col-md-4 form-item left">
                                                <Button variant="primary" type="submit" className="btn btn-success squared send-form">Send Message</Button>
                                            </div>
                                        </Form>
                                    </div>
                                    {/* <!-- EXISTING CUSTOMER CONTACT FROM --> */}
                                    <div className="col-md-8 col-md-offset-2 col-sm-12 tab-content" style={{ display: !this.state.newCust ? 'block' : 'none' as 'collapse' }}>
                                        <Form action="https://formspree.io/sales@direktek.co.uk" id="new" method="post" name="existing">
                                            <p className="text-center text-XL padding-bottom-L">We&#8217;re always here to support you</p>
                                            <Form.Group controlId="existCustomer">
                                                <div className="form-group col-sm-6 col-xs-12 form-item left" >
                                                    <Form.Control type="text" name="name" placeholder="Your name" required={true} />
                                                    <Form.Control type='hidden' name="formTitle" value="Existing customer contact form"></Form.Control>
                                                </div>
                                                <div className="form-group col-sm-6 col-xs-12 form-item">
                                                    <Form.Control type="email" name="_replyto" placeholder="Your e-mail" />
                                                </div>
                                                <div className="form-group col-sm-6 col-xs-12 form-item left">
                                                    <Form.Control type="text" name="accountRef" placeholder="Your company name/code" />
                                                </div>
                                                <div className="form-group col-sm-6 col-xs-12 form-item">
                                                    <Form.Control as='select' onChange={this.showExtraFields} name="nature" style={{ color: "#9e9e9e" + "!important" }}>
                                                        <option hidden={false} value="Nature of your query">
                                                            Nature of your query
                                                            </option>
                                                        <option data-show=".price-query" value="Price query" id="sales@direktek.co.uk" style={{ color: "#3e4143" + "!important" }}>
                                                            Pricing query
                                                            </option>
                                                        <option data-show=".where-is" value="Where is my order?" id="sales@direktek.co.uk" style={{ color: "#3e4143" + "!important" }}>
                                                            Where is my order?
                                                                </option>
                                                        <option data-show=".missing-parts" value="Missing parts / Technical advice" id="cs@direktek.co.uk" style={{ color: "#3e4143" + "!important" }}>
                                                            Missing parts/Technical advice
                                                                </option>
                                                        <option data-show=".return-req" style={{ color: "#3e4143" + "!important" }}>I'd like to return an item</option>
                                                        <option data-show=".accounts-query" value="Accounting query" id="creditcontrol@direktek.co.uk">
                                                            Accounting query
                                                                </option>
                                                        <option data-show=".other" value="Other / Not listed" id="sales@direktek.co.uk" style={{ color: "#3e4143" + "!important" }}>Other/Not listed</option>

                                                    </Form.Control>
                                                </div>
                                                <div className="extra-fields">
                                                    <div className={`form-group col-sm-6 col-xs-12 price-query missing-parts ${['.missing-parts', '.price-query'].includes(this.state.formExtras) ? '' : 'hide'} form-item left`} >
                                                        <Form.Control name="productCode" placeholder="Model number / Product code" type="text" />
                                                    </div>
                                                    <div className={`form-group col-sm-6 col-xs-12 where-is missing-parts ${['.missing-parts', '.where-is'].includes(this.state.formExtras) ? '' : 'hide'}  form-item left`}>
                                                        <Form.Control name="orderRef" placeholder="Order number / PO ref" type="text" />
                                                    </div>
                                                    <div className={`form-group col-sm-6 col-xs-12 accounts-query ${this.state.formExtras == '.accounts-query' ? '' : 'hide'} form-item left`}>
                                                        <Form.Control name="transactionRef" placeholder="Invoice number / Credit number" type="text" />
                                                    </div>
                                                    <div className={`form-group col-sm-6 col-xs-12 accounts-query ${this.state.formExtras == '.accounts-query' ? '' : 'hide'} form-item`}>
                                                        <Form.Control name="orderRef" placeholder="Order number / PO ref" type="text" />
                                                    </div>
                                                    <div className={`form-group col-sm-6 col-xs-12 other ${this.state.formExtras == '.other' ? '' : 'hide'} form-item left`}>
                                                        <Form.Control name="userDefinedNature" placeholder="Nature of query" type="text" />
                                                    </div>
                                                    <div className={`form-group col-xs-12 price-query where-is accounts-query other missing-parts ${this.state.formExtras != '' ? '' : 'hide'} form-item`}>
                                                        <Form.Control as="textarea" name="message" placeholder="Please provide as much information to help us investiagte your query easier." rows={3} />
                                                        <Form.Control name="_next" type="hidden" value="/contact" /> <Form.Control name="_format" type="hidden" value="plain" />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                            <div className={`form-group col-xs-12 col-sm-6 col-md-4 form-item ${this.state.formExtras == '' ? 'hide' : ''} left`}>
                                                <Button variant="primary" type="submit" className="btn btn-success squared send-form">Send Message</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className="extra-fields padding-top-S padding-bottom-S">
                            <div className="text-center text-M col-sm-12 col-md-4 col-md-offset-4 padding-top-M padding-bottom-M border-all return-req hide">
                                <p>All return requests are handled online, please complete our form to request a return.</p>
                                <a className="btn squared btn-red " href="javascript:void( window.open( 'https://form.jotformeu.com/Direktek/return-request-form', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=500' ) )">Request a Return</a>
                            </div>
                        </div><div className="padding-top-L padding-bottom-XL text-left">
                            <div className="container">
                                <div className="cta-box-light">
                                    <div className="col-sm-5 col-sm-offset-2 col-xs-12">
                                        <h3 className="text-green">Want to speak to a human?</h3>
                                    </div>
                                    <div className="col-sm-4 col-xs-12 text-left">
                                        <h3>Call us on <span className="text-red">01494 471 100</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="contact border-top border-bottom background-grey">
                            <div className="container">
                                <div className="col-sm-12 col-md-6 padding-top-L padding-bottom-L first-item">
                                    <h3 className="padding-bottom-S">New brand partners</h3>
                                    <p className="text-M">We&#8217;re always on the lookout for new products! Learn more <a href="brands">here</a> and email us at <a href="mailto:brandpartners@direktek.co.uk">brandpartners@direktek.co.uk</a></p>
                                </div>
                                <div className="col-sm-12 col-md-6 padding-top-L padding-bottom-L">
                                    <h3 className="padding-bottom-S">General</h3>
                                    <p className="text-M">For general queries, including press, technology, partnership opportunities, please email <a href="mailto:info@direktek.co.uk">info@direktek.co.uk</a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}


export default ContactUs;